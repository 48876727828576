<template>
    <v-container fluid fill-height class="error500">
        <div class="mx-auto" style="text-align: center; color: rgba(255, 0, 0, 0.8)">
            <h1>Sorry, something went wrong. Please come back later...</h1>
            <img style="width: 100%; height: auto;" src="../../assets/images/500.svg" alt="Server down"/>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Error500"
}
</script>
